.aggregate-box {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
}
.aggregate-box:hover {
    cursor: pointer;
    box-shadow: 0 10px 6px -6px #777;
}

.aggregate-box.active {
    box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    border: 3px solid #000;
}

.aggregate-box.red {
    background-color: #ff0000;
}

.aggregate-box.yellow {
    background-color: #FFBF00;
}

.aggregate-box.green {
    background-color: #33a532;
}

.aggregate-box .count {
    font-size: 35px;
    font-weight: bold;
}